<template>
  <div id="pdf" class="flex flex-col gap-4 p-4">
    <section class="flex justify-between items-center">
      <div class="flex gap-2">
        <font-awesome-icon icon="file-invoice" style="width: 25px; height: 25px;color: #1C3FAA"/>
        <span class="text-base font-bold" style="color: #1C3FAA">Informacion caja</span>
      </div>
      <Button icon="pi pi-arrow-left"
              class="p-badge-info"
              @click="$router.go(-1)"
      />
    </section>

    <section class="flex justify-end gap-4 items-center">
      <span class="text-base font-bold">Fecha</span>
      <input type="date" v-model="date" class="p-inputtext w-1/6">
      <Button icon="pi pi-file-pdf"
              class="p-badge-info"
              @click="generateReport()"
      />
    </section>

    <section class="flex flex-col gap-4">
      <div class="flex gap-4">

        <section class="box w-1/4 p-4 flex justify-center items-center">
          <div class="flex gap-4 justify-center items-center">
            <section>
              <div class="circulo-azul flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="25.3" height="39.236" viewBox="0 0 25.3 39.236">
                  <g id="Grupo_1479" data-name="Grupo 1479" transform="translate(-427 -1200.016)">
                    <path id="Trazado_28" data-name="Trazado 28" d="M23.537,34.226a12.355,12.355,0,0,0-7.067-5.65.458.458,0,0,1-.387-.53c.019-1.481.008-2.962.009-4.443,0-.123.011-.245.018-.391h.448c.97,0,1.94,0,2.911,0a1.135,1.135,0,0,0,1.108-.678,1.122,1.122,0,0,0-.248-1.3c-.077-.085-.16-.164-.242-.245q-3.128-3.129-6.259-6.253a11.128,11.128,0,0,0-.877-.714h-.613a3.521,3.521,0,0,0-.524.363q-3.42,3.4-6.827,6.823a1.113,1.113,0,0,0-.291,1.3,1.154,1.154,0,0,0,1.163.714q1.475,0,2.949,0c.123,0,.246.011.389.018,0,1.64-.008,3.247.007,4.854a.416.416,0,0,1-.349.48A12.638,12.638,0,0,0,.316,43.376a12.136,12.136,0,0,0,2.491,5.154,12.249,12.249,0,0,0,6.3,4.193c.9.253,1.846.358,2.77.53h1.533l.3-.06a19.44,19.44,0,0,0,2.693-.532,12.366,12.366,0,0,0,8.681-9.926,11.926,11.926,0,0,0-1.543-8.509M8.664,20.913l3.99-3.995,3.99,3.995c-.5,0-1.015,0-1.533,0a1.176,1.176,0,0,0-1.317,1.324q0,2.66,0,5.321v.429h-2.3V27.6q0-2.66,0-5.32c0-.944-.431-1.369-1.386-1.371H8.664m3.943,30.039a10.344,10.344,0,1,1,10.381-10.3,10.377,10.377,0,0,1-10.381,10.3" transform="translate(427 1186)" fill="#fff"/>
                    <path id="Trazado_29" data-name="Trazado 29" d="M126.827,266.8a3.273,3.273,0,0,1-1.941,3.021c-.26.129-.366.262-.336.543a4.135,4.135,0,0,1,0,.689,1.15,1.15,0,0,1-1.163,1.088,1.079,1.079,0,0,1-1.106-1.089,1.418,1.418,0,0,0-.851-1.533,3.079,3.079,0,0,1-1.474-2.743,1.127,1.127,0,0,1,1.059-1.183,1.144,1.144,0,0,1,1.215.986c.025.138.017.282.049.418a1.149,1.149,0,0,0,2.264-.225,1.12,1.12,0,0,0-.962-1.156,4.689,4.689,0,0,1-1.87-.487,3.322,3.322,0,0,1-1.744-3.14,3.248,3.248,0,0,1,1.937-2.94.51.51,0,0,0,.359-.568c-.027-.42-.015-.843,0-1.264a1.147,1.147,0,1,1,2.294.007c.008.229,0,.459,0,.689a1.122,1.122,0,0,0,.706,1.37,3.165,3.165,0,0,1,1.579,2.768,1.146,1.146,0,0,1-2.285.166c-.081-.741-.43-1.149-1.041-1.215a1.143,1.143,0,0,0-1.253.979,1.124,1.124,0,0,0,.943,1.275,4.687,4.687,0,0,1,1.871.481,3.33,3.33,0,0,1,1.754,3.063" transform="translate(316.239 962.51)" fill="#fff"/>
                  </g>
                </svg>
              </div>
            </section>
            <section class="flex flex-col">
              <span class="font-extrabold text-2xl" style="color: #3888F1">$ 143.814.00,00</span>
              <span>Total Ingresos</span>
            </section>
          </div>
        </section>

        <section class="flex box w-3/4 p-4">
          <div class="flex">

            <section>
              <div class="flex gap-12 items-center">

                <section>
                  <div class="circulo-amarillo flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.764" height="36.856" viewBox="0 0 23.764 36.856">
                      <g id="Grupo_1481" data-name="Grupo 1481" transform="translate(-854.146 -1186)">
                        <path id="Trazado_30" data-name="Trazado 30" d="M428.792,17.855a11.6,11.6,0,0,0,6.629,5.318.43.43,0,0,1,.363.5c-.02,1.391-.012,2.782-.016,4.173,0,.115-.01.23-.017.367h-.42c-.911,0-1.823-.006-2.734,0a1.066,1.066,0,0,0-1.042.635,1.054,1.054,0,0,0,.231,1.225c.072.08.15.154.227.231q2.933,2.944,5.87,5.883a10.425,10.425,0,0,0,.822.672h.576a3.3,3.3,0,0,0,.493-.34q3.217-3.193,6.423-6.4a1.045,1.045,0,0,0,.275-1.217,1.084,1.084,0,0,0-1.091-.673q-1.385,0-2.77-.005c-.116,0-.231-.011-.366-.017,0-1.54.012-3.05,0-4.559a.391.391,0,0,1,.328-.45,11.534,11.534,0,0,0,6.34-4.759,11.593,11.593,0,0,0,1.7-9.14A11.85,11.85,0,0,0,442.375.5c-.847-.239-1.733-.339-2.6-.5l-1.44,0-.279.056a18.25,18.25,0,0,0-2.53.5,11.615,11.615,0,0,0-8.169,9.31,11.2,11.2,0,0,0,1.436,7.994m13.95,12.528-3.754,3.746-3.741-3.759c.468,0,.954,0,1.44,0a1.105,1.105,0,0,0,1.239-1.242q.007-2.5.009-5v-.4l2.159,0v.361q0,2.5-.008,5c0,.887.4,1.287,1.3,1.29l1.356,0M439.085,2.162a9.716,9.716,0,1,1-9.766,9.657,9.747,9.747,0,0,1,9.766-9.657" transform="translate(427 1186)" fill="#fff"/>
                        <path id="Trazado_31" data-name="Trazado 31" d="M553.41,69.148a3.074,3.074,0,0,1-1.823,2.837c-.244.122-.343.246-.316.51a3.884,3.884,0,0,1,0,.647,1.08,1.08,0,0,1-1.093,1.022,1.013,1.013,0,0,1-1.039-1.023,1.332,1.332,0,0,0-.8-1.44,2.893,2.893,0,0,1-1.384-2.576,1.058,1.058,0,0,1,.995-1.111,1.075,1.075,0,0,1,1.142.926c.023.13.016.265.046.392a1.079,1.079,0,0,0,2.126-.212,1.052,1.052,0,0,0-.9-1.086,4.406,4.406,0,0,1-1.756-.457,3.12,3.12,0,0,1-1.638-2.95,3.051,3.051,0,0,1,1.82-2.761.479.479,0,0,0,.337-.533c-.025-.394-.014-.792,0-1.187a1.077,1.077,0,1,1,2.154.006c.007.215,0,.431,0,.647a1.054,1.054,0,0,0,.663,1.287,2.973,2.973,0,0,1,1.483,2.6,1.076,1.076,0,0,1-2.146.156c-.076-.7-.4-1.079-.978-1.142a1.074,1.074,0,0,0-1.177.919,1.056,1.056,0,0,0,.886,1.2,4.4,4.4,0,0,1,1.758.452,3.128,3.128,0,0,1,1.648,2.877" transform="translate(315.816 1131.197)" fill="#fff"/>
                      </g>
                    </svg>
                  </div>
                </section>
                <section class="flex flex-col">
                  <span class="font-extrabold text-2xl" style="color: #FDC942">$ 143.814.00,00</span>
                  <span>Total Egresos</span>
                </section>

                <div class="border-r-2 h-14"></div>

                <section class="grid grid-cols-3 gap-4 gap-x-12">
                  <div class="flex gap-2">
                    <div class="viñeta"></div>
                    <section class="flex flex-col">
                      <span>Facturas</span>
                      <span style="color: #1C3FAA" class="font-extrabold text-lg">$ 143.814.00,00</span>
                    </section>
                  </div>
                  <div class="flex gap-2">
                    <div class="viñeta"></div>
                    <section class="flex flex-col">
                      <span>Facturas</span>
                      <span style="color: #1C3FAA" class="font-extrabold text-lg">$ 143.814.00,00</span>
                    </section>
                  </div>
                  <div class="flex gap-2">
                    <div class="viñeta"></div>
                    <section class="flex flex-col">
                      <span>Facturas</span>
                      <span style="color: #1C3FAA" class="font-extrabold text-lg">$ 143.814.00,00</span>
                    </section>
                  </div>
                  <div class="flex gap-2">
                    <div class="viñeta"></div>
                    <section class="flex flex-col">
                      <span>Facturas</span>
                      <span style="color: #1C3FAA" class="font-extrabold text-lg">$ 143.814.00,00</span>
                    </section>
                  </div>
                  <div class="flex gap-2">
                    <div class="viñeta"></div>
                    <section class="flex flex-col">
                      <span>Facturas</span>
                      <span style="color: #1C3FAA" class="font-extrabold text-lg">$ 143.814.00,00</span>
                    </section>
                  </div>
                </section>
              </div>
            </section>

          </div>
        </section>

      </div>
      <div class="flex gap-4">

        <section class="flex w-1/2 box justify-between p-4 items-center">
          <font-awesome-icon icon="money-bill-alt" style="width: 50px; height: 50px;color: #1C3FAA"/>
          <span style="color: #3888F1" class="text-3xl font-bold">$ 143.814.00,00</span>
          <span class="text-2xl">Saldo Inicial Caja</span>
        </section>

        <section class="flex w-1/2 box justify-between p-4 items-center">
          <font-awesome-icon icon="money-bill-alt" style="width: 50px; height: 50px;color: #1C3FAA"/>
          <span style="color: #3888F1" class="text-3xl font-bold">$ 143.814.00,00</span>
          <span class="text-2xl">Saldo Caja</span>
        </section>

      </div>
      <div class="grid grid-cols-4 gap-4">
        <section class="flex flex-col">
          <div class="flex flex-col box p-4 gap-4">
            <div class="flex justify-between">
              <span class="font-bold">Cierre Caja</span>
              <span>2020-02-08 16:4:23</span>
            </div>
            <div class="flex justify-between">
              <span class="font-bold">Total Billetes</span>
              <span>$ 0,00</span>
            </div>
            <div class="flex justify-between">
              <span class="font-bold">Total Monedas</span>
              <span>$ 0,00</span>
            </div>
            <div class="border-t-2"></div>
            <div class="flex justify-between">
              <span class="font-bold">Total Efectivo</span>
              <span>$ 0,00</span>
            </div>
            <div class="flex justify-between">
              <span class="font-bold">Efectivo + Pendientes + Préstamos</span>
              <span>$ 0,00</span>
            </div>
            <div class="border-t-2"></div>
            <div class="flex justify-between">
              <span class="font-bold">Saldo Caja</span>
              <span>$ 0,00</span>
            </div>
          </div>
          <div class="w-full text-white p-2" style="background-color: #3686EF">
            Resumen
          </div>
          <div class="flex flex-col box p-4 gap-4">
            <span style="color: #1C3FAA" class="font-bold">Billetes</span>
            <div class="border-t-2"></div>
            <section class="flex flex-col gap-4">
              <div class="flex justify-between">
                <span>$100.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$50.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$20.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$10.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$5.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$2.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$1.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
            </section>
            <span style="color: #1C3FAA" class="font-bold">Monedas</span>
            <div class="border-t-2"></div>
            <section class="flex flex-col gap-4">
              <div class="flex justify-between">
                <span>$1.000,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$500,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$200,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$100,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
              <div class="flex justify-between">
                <span>$50,00</span>
                <span>0</span>
                <span>$ 0,00</span>
              </div>
              <div class="border-t-2"></div>
            </section>
          </div>
          <div class="flex box mt-4 p-4 justify-between">
            <span class="font-bold">Arqueo Supervizado</span>
            <span>2020-02-08 13:25:02</span>
          </div>
          <div class="w-full text-white p-2 flex gap-4 items-center pl-4" style="background-color: #41CB83">
            <font-awesome-icon icon="check-circle" style="width: 20px; height: 20px;"/>
            <span>Sin Inconsistencias</span>
          </div>
          <div class="flex flex-col box p-4 gap-4">
            <div class="flex justify-between">
              <span class="font-bold">Cierre Caja</span>
              <span>2020-02-08 16:4:23</span>
            </div>
            <div class="flex justify-between">
              <span class="font-bold">Total Billetes</span>
              <span>$ 0,00</span>
            </div>
            <div class="flex justify-between">
              <span class="font-bold">Total Monedas</span>
              <span>$ 0,00</span>
            </div>
            <div class="border-t-2"></div>
            <div class="flex justify-between">
              <span class="font-bold">Total Efectivo</span>
              <span>$ 0,00</span>
            </div>
            <div class="flex justify-between">
              <span class="font-bold">Efectivo + Pendientes + Préstamos</span>
              <span>$ 0,00</span>
            </div>
            <div class="border-t-2"></div>
            <div class="flex justify-between">
              <span class="font-bold">Saldo Caja</span>
              <span>$ 0,00</span>
            </div>
          </div>

        </section>
        <section class="flex flex-col col-span-3 w-full gap-4">

          <div class="flex flex-col box w-full p-4 gap-4">
            <section class="flex items-center gap-4">
              <div class="circulo-pequeño-azul flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23.26" viewBox="0 0 25.3 39.236">
                  <g id="Grupo_1479" data-name="Grupo 1479" transform="translate(-427 -1200.016)">
                    <path id="Trazado_28" data-name="Trazado 28" d="M23.537,34.226a12.355,12.355,0,0,0-7.067-5.65.458.458,0,0,1-.387-.53c.019-1.481.008-2.962.009-4.443,0-.123.011-.245.018-.391h.448c.97,0,1.94,0,2.911,0a1.135,1.135,0,0,0,1.108-.678,1.122,1.122,0,0,0-.248-1.3c-.077-.085-.16-.164-.242-.245q-3.128-3.129-6.259-6.253a11.128,11.128,0,0,0-.877-.714h-.613a3.521,3.521,0,0,0-.524.363q-3.42,3.4-6.827,6.823a1.113,1.113,0,0,0-.291,1.3,1.154,1.154,0,0,0,1.163.714q1.475,0,2.949,0c.123,0,.246.011.389.018,0,1.64-.008,3.247.007,4.854a.416.416,0,0,1-.349.48A12.638,12.638,0,0,0,.316,43.376a12.136,12.136,0,0,0,2.491,5.154,12.249,12.249,0,0,0,6.3,4.193c.9.253,1.846.358,2.77.53h1.533l.3-.06a19.44,19.44,0,0,0,2.693-.532,12.366,12.366,0,0,0,8.681-9.926,11.926,11.926,0,0,0-1.543-8.509M8.664,20.913l3.99-3.995,3.99,3.995c-.5,0-1.015,0-1.533,0a1.176,1.176,0,0,0-1.317,1.324q0,2.66,0,5.321v.429h-2.3V27.6q0-2.66,0-5.32c0-.944-.431-1.369-1.386-1.371H8.664m3.943,30.039a10.344,10.344,0,1,1,10.381-10.3,10.377,10.377,0,0,1-10.381,10.3" transform="translate(427 1186)" fill="#fff"/>
                    <path id="Trazado_29" data-name="Trazado 29" d="M126.827,266.8a3.273,3.273,0,0,1-1.941,3.021c-.26.129-.366.262-.336.543a4.135,4.135,0,0,1,0,.689,1.15,1.15,0,0,1-1.163,1.088,1.079,1.079,0,0,1-1.106-1.089,1.418,1.418,0,0,0-.851-1.533,3.079,3.079,0,0,1-1.474-2.743,1.127,1.127,0,0,1,1.059-1.183,1.144,1.144,0,0,1,1.215.986c.025.138.017.282.049.418a1.149,1.149,0,0,0,2.264-.225,1.12,1.12,0,0,0-.962-1.156,4.689,4.689,0,0,1-1.87-.487,3.322,3.322,0,0,1-1.744-3.14,3.248,3.248,0,0,1,1.937-2.94.51.51,0,0,0,.359-.568c-.027-.42-.015-.843,0-1.264a1.147,1.147,0,1,1,2.294.007c.008.229,0,.459,0,.689a1.122,1.122,0,0,0,.706,1.37,3.165,3.165,0,0,1,1.579,2.768,1.146,1.146,0,0,1-2.285.166c-.081-.741-.43-1.149-1.041-1.215a1.143,1.143,0,0,0-1.253.979,1.124,1.124,0,0,0,.943,1.275,4.687,4.687,0,0,1,1.871.481,3.33,3.33,0,0,1,1.754,3.063" transform="translate(316.239 962.51)" fill="#fff"/>
                  </g>
                </svg>
              </div>
              <span class="font-bold" style="color: #3888F1">$ 143.814.00,00</span>
              <span>Total Ingresos</span>
            </section>

            <section class="flex p-4">
              <DataTable :value="totalIncomes"
                         responsiveLayout="scroll"
                         class="w-full text-sm p-datatable-sm"
              >
                <Column field="client" header="Cliente" headerStyle="background-color: #3686EF;"></Column>
                <Column field="concept" header="Concepto" headerStyle="background-color: #3686EF;"></Column>
                <Column field="value" header="Valor" headerStyle="background-color: #3686EF;"></Column>
              </DataTable>
            </section>

          </div>

          <div class="flex flex-col box w-full p-4 gap-4">
            <section class="flex items-center gap-4">
              <div class="circulo-amarillo-pequeño flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23.26" viewBox="0 0 23.764 36.856">
                  <g id="Grupo_1481" data-name="Grupo 1481" transform="translate(-854.146 -1186)">
                    <path id="Trazado_30" data-name="Trazado 30" d="M428.792,17.855a11.6,11.6,0,0,0,6.629,5.318.43.43,0,0,1,.363.5c-.02,1.391-.012,2.782-.016,4.173,0,.115-.01.23-.017.367h-.42c-.911,0-1.823-.006-2.734,0a1.066,1.066,0,0,0-1.042.635,1.054,1.054,0,0,0,.231,1.225c.072.08.15.154.227.231q2.933,2.944,5.87,5.883a10.425,10.425,0,0,0,.822.672h.576a3.3,3.3,0,0,0,.493-.34q3.217-3.193,6.423-6.4a1.045,1.045,0,0,0,.275-1.217,1.084,1.084,0,0,0-1.091-.673q-1.385,0-2.77-.005c-.116,0-.231-.011-.366-.017,0-1.54.012-3.05,0-4.559a.391.391,0,0,1,.328-.45,11.534,11.534,0,0,0,6.34-4.759,11.593,11.593,0,0,0,1.7-9.14A11.85,11.85,0,0,0,442.375.5c-.847-.239-1.733-.339-2.6-.5l-1.44,0-.279.056a18.25,18.25,0,0,0-2.53.5,11.615,11.615,0,0,0-8.169,9.31,11.2,11.2,0,0,0,1.436,7.994m13.95,12.528-3.754,3.746-3.741-3.759c.468,0,.954,0,1.44,0a1.105,1.105,0,0,0,1.239-1.242q.007-2.5.009-5v-.4l2.159,0v.361q0,2.5-.008,5c0,.887.4,1.287,1.3,1.29l1.356,0M439.085,2.162a9.716,9.716,0,1,1-9.766,9.657,9.747,9.747,0,0,1,9.766-9.657" transform="translate(427 1186)" fill="#fff"/>
                    <path id="Trazado_31" data-name="Trazado 31" d="M553.41,69.148a3.074,3.074,0,0,1-1.823,2.837c-.244.122-.343.246-.316.51a3.884,3.884,0,0,1,0,.647,1.08,1.08,0,0,1-1.093,1.022,1.013,1.013,0,0,1-1.039-1.023,1.332,1.332,0,0,0-.8-1.44,2.893,2.893,0,0,1-1.384-2.576,1.058,1.058,0,0,1,.995-1.111,1.075,1.075,0,0,1,1.142.926c.023.13.016.265.046.392a1.079,1.079,0,0,0,2.126-.212,1.052,1.052,0,0,0-.9-1.086,4.406,4.406,0,0,1-1.756-.457,3.12,3.12,0,0,1-1.638-2.95,3.051,3.051,0,0,1,1.82-2.761.479.479,0,0,0,.337-.533c-.025-.394-.014-.792,0-1.187a1.077,1.077,0,1,1,2.154.006c.007.215,0,.431,0,.647a1.054,1.054,0,0,0,.663,1.287,2.973,2.973,0,0,1,1.483,2.6,1.076,1.076,0,0,1-2.146.156c-.076-.7-.4-1.079-.978-1.142a1.074,1.074,0,0,0-1.177.919,1.056,1.056,0,0,0,.886,1.2,4.4,4.4,0,0,1,1.758.452,3.128,3.128,0,0,1,1.648,2.877" transform="translate(315.816 1131.197)" fill="#fff"/>
                  </g>
                </svg>
              </div>
              <span class="font-bold" style="color: #3888F1">$ 143.814.00,00</span>
              <span>Total Egresos</span>
            </section>

            <section class="flex flex-col p-4 gap-4">
              <div>
                <span class="font-bold" style="color: #1C3FAA">Factura $ 00,00</span>
                <DataTable :value="totalIncomes"
                           responsiveLayout="scroll"
                           class="w-full text-sm p-datatable-sm"
                >
                  <Column field="client" header="Cliente" headerStyle="background-color: #3686EF;"></Column>
                  <Column field="concept" header="Concepto" headerStyle="background-color: #3686EF;"></Column>
                  <Column field="value" header="Valor" headerStyle="background-color: #3686EF;"></Column>
                </DataTable>
              </div>

              <div>
                <span class="font-bold" style="color: #1C3FAA">Anticipo / Responsabilidad $ 00,00</span>
                <DataTable :value="totalIncomes"
                           responsiveLayout="scroll"
                           class="w-full text-sm p-datatable-sm"
                >
                  <Column field="client" header="Cliente" headerStyle="background-color: #3686EF;"></Column>
                  <Column field="concept" header="Concepto" headerStyle="background-color: #3686EF;"></Column>
                  <Column field="value" header="Valor" headerStyle="background-color: #3686EF;"></Column>
                </DataTable>
              </div>

              <div>
                <span class="font-bold" style="color: #1C3FAA">Reembolso Caja Sede $ 00,00</span>
                <DataTable :value="totalIncomes"
                           responsiveLayout="scroll"
                           class="w-full text-sm p-datatable-sm"
                >
                  <Column field="client" header="Cliente" headerStyle="background-color: #3686EF;"></Column>
                  <Column field="concept" header="Concepto" headerStyle="background-color: #3686EF;"></Column>
                  <Column field="value" header="Valor" headerStyle="background-color: #3686EF;"></Column>
                </DataTable>
              </div>

              <div>
                <span class="font-bold" style="color: #FCC73B">Pendientes $ 00,00</span>
                <DataTable :value="totalIncomes"
                           responsiveLayout="scroll"
                           class="w-full text-sm p-datatable-sm"
                >
                  <Column field="client" header="Cliente" headerStyle="background-color: #FCC73B;"></Column>
                  <Column field="concept" header="Concepto" headerStyle="background-color: #FCC73B;"></Column>
                  <Column field="value" header="Valor" headerStyle="background-color: #FCC73B;"></Column>
                </DataTable>
              </div>

              <div>
                <span class="font-bold" style="color: #FCC73B">Préstamos $ 00,00</span>
                <DataTable :value="totalIncomes"
                           responsiveLayout="scroll"
                           class="w-full text-sm p-datatable-sm"
                >
                  <Column field="client" header="Cliente" headerStyle="background-color: #FCC73B;"></Column>
                  <Column field="concept" header="Concepto" headerStyle="background-color: #FCC73B;"></Column>
                  <Column field="value" header="Valor" headerStyle="background-color: #FCC73B;"></Column>
                </DataTable>
              </div>
            </section>
            <div class="rounded-3xl text-white p-4 flex justify-between font-bold text-xl" style="background-color: #0F4AD5">
              <span>Egresos - (Pendientes+Préstamos)</span>
              <span>$ 00,00</span>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'InformeCaja',
  setup () {
    const date = ref('2021-08-01')
    const totalIncomes = ref([
      {
        client: 'Cliente 1',
        concept: 'Concepto 1',
        value: 100000
      },
      {
        client: 'Cliente 2',
        concept: 'Concepto 2',
        value: 100000
      },
      {
        client: 'Cliente 3',
        concept: 'Concepto 3',
        value: 100000
      }
    ])

    const generateReport = () => {
      console.log('Generar reporte')
    }

    return {
      date,
      totalIncomes,
      generateReport
    }
  }
}
</script>

<style scoped>
.circulo-azul {
  width: 4rem;
  height: 4rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #3989F1 0%, #116ADE 100%) 0 0 no-repeat padding-box;
  box-shadow: 0 0 0 0.5rem rgb(139 185 246 / 0.2);
}

.circulo-pequeño-azul {
  width: 2rem;
  height: 2rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #3989F1 0%, #116ADE 100%) 0 0 no-repeat padding-box;
  box-shadow: 0 0 0 0.3rem rgb(139 185 246 / 0.2);
}

.circulo-amarillo {
  width: 4rem;
  height: 4rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #FFCC47 0%, #F3B91D 100%) 0 0 no-repeat padding-box;
  box-shadow: 0 0 0 0.5rem rgb(254 202 66 / 0.2);
}

.circulo-amarillo-pequeño {
  width: 2rem;
  height: 2rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #FFCC47 0%, #F3B91D 100%) 0 0 no-repeat padding-box;
  box-shadow: 0 0 0 0.5rem rgb(254 202 66 / 0.2);
}

.viñeta {
  width: 0.8rem;
  height: 0.8rem;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #1C3FAA;
}
</style>
